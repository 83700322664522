window.graphHandlers = {}
window.graphData = window.processGraph({
  //'-1': ['1', 'before_1'],
  // '1': [],
  //     '4': [ '1', 'b' ],
  //     '5': [],
  //     '8': [ '5', 'v' ],
  //     '11': [ 'w', '1z' ],
  //     '12': [],
  //     '15': [ '12', '11', '1a' ],
  //     '20': [],
  //     '25': [ '20', '2r', '2n', '2x' ],
  //     '26': [],
  //     '29': [ '26', '25', '2e' ],
  //     '31': [ '2y', '2x', '36' ],
  //     '36': [ '2x' ],
  //     '54': [ '4z', '5x', '6h' ],
  //     '55': [],
  //     '58': [ '55', '54', '5d' ],
  //     '63': [ '5y', '71' ],
  //     '64': [],
  //     '67': [ '64', '63', '6c' ],
  //     '71': [],
  //     '72': [],
  //     '77': [ '72', '8d', '89', '8j' ],
  //     '78': [],
  //     '80': [ '7r' ],
  //     '85': [],
  //     '86': [],
  //     '89': [ '86', '8x' ],
  //     '93': [ '8y' ],
  //     '94': [],
  //     '97': [ '94', '93', '9c' ],
  // b: [ '8' ],
  // h: [ 'c', '1f', '2j' ],
  // c: [],
  // l: [ 'i', 'h', 'q' ],
  // i: [],
  // q: [ 'h' ],
  // v: [ 'h' ],
  // w: [],
  // '1a': [ '11' ],
  // '1f': [],
  // '1l': [ '1g', '2p', '2n', '2x' ],
  // '1g': [],
  // '1p': [ '1m', '1l', '1u' ],
  // '1m': [],
  // '1u': [ '1l' ],
  // '1z': [],
  // '2e': [ '25' ],
  // '2j': [],
  // '2n': [ '2k', '3b' ],
  // '2k': [],
  // '2p': [],
  // '2r': [],
  // '2x': [ '2s', '3v' ],
  // '2s': [],
  // '2y': [],
  // '3b': [ '2x' ],
  // '3h': [ '3c', '3x' ],
  // '3c': [],
  // '3l': [ '3i', '3h', '3q' ],
  // '3i': [],
  // '3q': [ '3h' ],
  // '3v': [],
  // '3x': [],
  // '4z': [],
  // '5d': [ '54' ],
  // '5j': [ '5e', '85' ],
  // '5e': [],
  // '5n': [ '5k', '5j', '5s' ],
  // '5k': [],
  // '5s': [ '5j' ],
  // '5x': [],
  // '5y': [],
  // '6c': [ '63' ],
  // '6h': [],
  // '6n': [ '6i', '7l' ],
  // '6i': [],
  // '6r': [ '6o', '6n', '6w' ],
  // '6o': [],
  // '6w': [ '6n' ],
  // '7b': [ '78', '77', '7g' ],
  // '7g': [ '77' ],
  // '7l': [],
  // '7r': [ '7m', '8b', '89', '8j' ],
  // '7m': [],
  // '7v': [ '7s', '7r', '80' ],
  // '7s': [],
  // '8b': [],
  // '8d': [],
  // '8j': [ '8e', '9h' ],
  // '8e': [],
  // '8n': [ '8k', '8j', '8s' ],
  // '8k': [],
  // '8s': [ '8j' ],
  // '8x': [ '8j' ],
  // '8y': [],
  // '9c': [ '93' ],
  // '9h': [],
  // '9l': [ '9i', '9s' ],
  // '9i': [],
  // '9p': [ '9m', '9x' ],
  // '9m': [],
  // '9s': [ '9p' ],
  // '9x': [ '54' ],
  before_1: ['point_1'],
  point_1: ['after_1'],
  after_1: ['before_3'],
  before_2: ['point_2'],
  point_2: ['after_2'],
  after_2: ['before_8'],
  before_3: ['point_3'],
  point_3: ['after_3'],
  after_3: ['before_2'],
  before_4: ['point_4'],
  point_4: ['after_4'],
  after_4: ['before_d', 'before_n'],
  before_5: ['point_5'],
  point_5: ['after_5'],
  after_5: ['before_6', 'before_7'],
  before_6: ['point_6'],
  point_6: ['after_6'],
  after_6: ['before_4'],
  before_7: ['point_7'],
  point_7: ['after_7'],
  after_7: ['before_4'],
  before_8: ['point_8'],
  point_8: ['after_8'],
  after_8: ['before_4'],
  before_9: ['point_9'],
  point_9: ['after_9'],
  after_9: ['before_i'],
  before_a: ['point_a'],
  point_a: ['after_a'],
  after_a: ['before_b', 'before_c'],
  before_b: ['point_b'],
  point_b: ['after_b'],
  after_b: ['before_9'],
  before_c: ['point_c'],
  point_c: ['after_c'],
  after_c: ['before_9'],
  before_d: ['point_d'],
  point_d: ['after_d'],
  after_d: ['before_9'],
  before_e: ['point_e'],
  point_e: ['after_e'],
  after_e: ['before_u'],
  before_f: ['point_f'],
  point_f: ['after_f'],
  after_f: ['before_g', 'before_h'],
  before_g: ['point_g'],
  point_g: ['after_g'],
  after_g: ['before_e'],
  before_h: ['point_h'],
  point_h: ['after_h'],
  after_h: ['before_e'],
  before_i: ['point_i'],
  point_i: ['after_i'],
  after_i: ['before_e'],
  before_j: ['point_j'],
  point_j: ['after_j'],
  after_j: ['before_v'],
  before_k: ['point_k'],
  point_k: ['after_k'],
  after_k: ['before_l', 'before_m'],
  before_l: ['point_l'],
  point_l: ['after_l'],
  after_l: ['before_j'],
  before_m: ['point_m'],
  point_m: ['after_m'],
  after_m: ['before_j'],
  before_n: ['point_n'],
  point_n: ['after_n'],
  after_n: ['before_j'],
  before_o: ['point_o'],
  point_o: ['after_o'],
  after_o: ['before_t'],
  before_p: ['point_p'],
  point_p: ['after_p'],
  after_p: ['before_10'],
  before_q: ['point_q'],
  point_q: ['after_q'],
  after_q: ['before_r', 'before_s'],
  before_r: ['point_r'],
  point_r: ['after_r'],
  after_r: ['before_p'],
  before_s: ['point_s'],
  point_s: ['after_s'],
  after_s: ['before_p'],
  before_t: ['point_t'],
  point_t: ['after_t'],
  after_t: ['before_p'],
  before_u: ['point_u'],
  point_u: ['after_u'],
  after_u: ['before_p'],
  before_v: ['point_v'],
  point_v: ['after_v'],
  after_v: ['before_p'],
  before_w: ['point_w'],
  point_w: ['after_w'],
  after_w: ['before_12'],
  before_x: ['point_x'],
  point_x: ['after_x'],
  after_x: ['before_y', 'before_z'],
  before_y: ['point_y'],
  point_y: ['after_y'],
  after_y: ['before_w'],
  before_z: ['point_z'],
  point_z: ['after_z'],
  after_z: ['before_w'],
  before_10: ['point_10'],
  point_10: ['after_10'],
  after_10: ['before_w'],
  before_11: ['point_11'],
  point_11: ['after_11'],
  after_11: [],
  before_12: ['point_12'],
  point_12: ['after_12'],
  after_12: ['before_11'],
  before_13: ['point_13'],
  point_13: ['after_13'],
  after_13: ['before_1b', 'before_1g'],
  before_14: ['point_14'],
  point_14: ['after_14'],
  after_14: ['before_15', 'before_16'],
  before_15: ['point_15'],
  point_15: ['after_15'],
  after_15: ['before_13'],
  before_16: ['point_16'],
  point_16: ['after_16'],
  after_16: ['before_13'],
  before_17: ['point_17'],
  point_17: ['after_17'],
  after_17: ['before_1v'],
  before_18: ['point_18'],
  point_18: ['after_18'],
  after_18: ['before_19', 'before_1a'],
  before_19: ['point_19'],
  point_19: ['after_19'],
  after_19: ['before_17'],
  before_1a: ['point_1a'],
  point_1a: ['after_1a'],
  after_1a: ['before_17'],
  before_1b: ['point_1b'],
  point_1b: ['after_1b'],
  after_1b: ['before_17'],
  before_1c: ['point_1c'],
  point_1c: ['after_1c'],
  after_1c: ['before_1l'],
  before_1d: ['point_1d'],
  point_1d: ['after_1d'],
  after_1d: ['before_1e', 'before_1f'],
  before_1e: ['point_1e'],
  point_1e: ['after_1e'],
  after_1e: ['before_1c'],
  before_1f: ['point_1f'],
  point_1f: ['after_1f'],
  after_1f: ['before_1c'],
  before_1g: ['point_1g'],
  point_1g: ['after_1g'],
  after_1g: ['before_1c'],
  before_1h: ['point_1h'],
  point_1h: ['after_1h'],
  after_1h: ['before_1q'],
  before_1i: ['point_1i'],
  point_1i: ['after_1i'],
  after_1i: ['before_1j', 'before_1k'],
  before_1j: ['point_1j'],
  point_1j: ['after_1j'],
  after_1j: ['before_1h'],
  before_1k: ['point_1k'],
  point_1k: ['after_1k'],
  after_1k: ['before_1h'],
  before_1l: ['point_1l'],
  point_1l: ['after_1l'],
  after_1l: ['before_1h'],
  before_1m: ['point_1m'],
  point_1m: ['after_1m'],
  after_1m: ['before_23'],
  before_1n: ['point_1n'],
  point_1n: ['after_1n'],
  after_1n: ['before_1o', 'before_1p'],
  before_1o: ['point_1o'],
  point_1o: ['after_1o'],
  after_1o: ['before_1m'],
  before_1p: ['point_1p'],
  point_1p: ['after_1p'],
  after_1p: ['before_1m'],
  before_1q: ['point_1q'],
  point_1q: ['after_1q'],
  after_1q: ['before_1m'],
  before_1r: ['point_1r'],
  point_1r: ['after_1r'],
  after_1r: ['before_22'],
  before_1s: ['point_1s'],
  point_1s: ['after_1s'],
  after_1s: ['before_1t', 'before_1u'],
  before_1t: ['point_1t'],
  point_1t: ['after_1t'],
  after_1t: ['before_1r'],
  before_1u: ['point_1u'],
  point_1u: ['after_1u'],
  after_1u: ['before_1r'],
  before_1v: ['point_1v'],
  point_1v: ['after_1v'],
  after_1v: ['before_1r'],
  before_1w: ['point_1w'],
  point_1w: ['after_1w'],
  after_1w: ['before_21'],
  before_1x: ['point_1x'],
  point_1x: ['after_1x'],
  after_1x: ['before_28'],
  before_1y: ['point_1y'],
  point_1y: ['after_1y'],
  after_1y: ['before_1z', 'before_20'],
  before_1z: ['point_1z'],
  point_1z: ['after_1z'],
  after_1z: ['before_1x'],
  before_20: ['point_20'],
  point_20: ['after_20'],
  after_20: ['before_1x'],
  before_21: ['point_21'],
  point_21: ['after_21'],
  after_21: ['before_1x'],
  before_22: ['point_22'],
  point_22: ['after_22'],
  after_22: ['before_1x'],
  before_23: ['point_23'],
  point_23: ['after_23'],
  after_23: ['before_1x'],
  before_24: ['point_24'],
  point_24: ['after_24'],
  after_24: [],
  before_25: ['point_25'],
  point_25: ['after_25'],
  after_25: ['before_26', 'before_27'],
  before_26: ['point_26'],
  point_26: ['after_26'],
  after_26: ['before_24'],
  before_27: ['point_27'],
  point_27: ['after_27'],
  after_27: ['before_24'],
  before_28: ['point_28'],
  point_28: ['after_28'],
  after_28: ['before_24'],
  before_29: ['point_29'],
  point_29: ['after_29'],
  after_29: ['before_2b'],
  before_2a: ['point_2a'],
  point_2a: ['after_2a'],
  after_2a: ['before_2c'],
  before_2b: ['point_2b'],
  point_2b: ['after_2b'],
  after_2b: ['before_2a'],
  before_2c: ['point_2c'],
  point_2c: ['after_2c'],
  after_2c: ['before_13'],
})
